import React, { useMemo } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { mergePrismicPreviewData } from "gatsby-source-prismic";
import { Row, Col } from "styled-bootstrap-grid";

const Page = styled.div`
  margin: 117px auto 0 auto;
  // max-width: 1400px;

  @media (max-width: 767px) {
    margin: 75px 0 0 0;
  }

  & h2 {
    text-align: center;
    margin-bottom: calc(75px - 1em);

    @media (max-width: 767px) {
      margin-bottom: 25px;
    }
  }

  & .text-column {
    margin-bottom: calc(150px - 1em);

    & p:first-of-type {
      margin-top: 0;
    }

    @media (max-width: 767px) {
      margin-bottom: 50px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    & .social-links {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      // justify-content: center;

      margin-top: 1em;

      a {
        font-size: 20px;
        line-height: 28px;

        text-decoration: none;

        margin-right: 10px;

        &:last-of-type {
          margin: 0;
        }

        @media (max-width: 1000px) {
          font-size: 18px;
          line-height: 26px;
        }
      }
    }
  }

  & .press-title {
    text-align: center;
  }

  & .biography {
    margin: 0 0 3em 0;
  }

  & .press-text {
    & a {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;

      text-decoration: underline;
      text-decoration-color: #818181 !important;
    }
  }

  & p:last-of-type {
    margin: 0;
  }

  & .press-link {
    margin: 0 0 2em 0;
  }
`;

const PartnerLogoContainer = styled.div`
  display: flex;
  flex-direciton: row;
  flex-wrap: wrap;
  align-items: center;

  & div {
    flex: 33.333%;
    max-width: 33.333%;

    padding: 15px 15px 15px 0;

    & img {
      max-width: 150px;
    }
  }
`;

// Returns true if we're in a browser, false otherwise. This will help guard
// against SSR issues when building the site.
const IS_BROWSER = typeof window !== "undefined";

const About = ({ data: staticData }) => {
  const data = useMemo(() => {
    // If we're not in a browser (i.e. we're in SSR) or preview data has not been
    // set, use the non-preview static data.
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData;

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    });
  }, [staticData]);

  const biographies = data.prismicAbout.data.biographies.map(
    (content, index) => (
      <div key={`press_text_${index}`} className="biography">
        {/* <div
          className="text press-title"
          dangerouslySetInnerHTML={{
            __html: content.name.html,
          }}
        /> */}
        <div
          className="text press-text"
          dangerouslySetInnerHTML={{
            __html: content.text.html,
          }}
        />
      </div>
    )
  );

  const press = data.prismicAbout.data.press_text.map((content, index) => (
    <div key={`press_text_${index}`}>
      <div
        className="text"
        dangerouslySetInnerHTML={{
          __html: content.text.html,
        }}
      />
      <div
        className="press-link"
        dangerouslySetInnerHTML={{
          __html: content.link.html,
        }}
      />
    </div>
  ));

  const partnerLogos = data.prismicAbout.data.partner_logos.map(
    (content, index) => (
      <div key={`partner_logo_${index}`}>
        <img src={content.logo.url} alt={content.logo.alt} />
      </div>
    )
  );

  return (
    <Page>
      <Helmet title="About – A Delicate Sight" />

      <Row justifyContent="around">
        <Col col={12} md={6}>
          <div className="text-column">
            <h2>About</h2>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.biography_text.html,
              }}
            />
          </div>

          <div className="text-column">
            <h2>Biographies</h2>
            {biographies}
          </div>
        </Col>

        <Col col={12} md={6}>
          {press.length >= 1 && (
            <div className="text-column">
              <h2>News</h2>
              <div>{press}</div>
            </div>
          )}

          <div className="text-column">
            <h2>Project Partners</h2>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.partners.html,
              }}
            />

            <PartnerLogoContainer>{partnerLogos}</PartnerLogoContainer>
          </div>

          <div className="text-column">
            <h2>Design Team</h2>
            <div
              className="text"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.design_team.html,
              }}
            />
          </div>
        </Col>
      </Row>
    </Page>
  );
};

export const query = graphql`
  {
    prismicAbout {
      data {
        biography_text {
          html
        }
        biographies {
          name {
            html
          }
          text {
            html
          }
        }
        press_text {
          link {
            html
          }
          text {
            html
          }
        }
        design_team {
          html
        }
        partners {
          html
        }
        partner_logos {
          logo {
            alt
            url
          }
        }
      }
    }
  }
`;

export default About;
